import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";

export const loadReferenceMembers = createAsyncThunk(
  "loadReferenceMembers",
  async (page: string, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(`${dynamic_url}/reference-members`);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addReferenceMembers = createAsyncThunk(
  "addReferenceMembers",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.post(
        `${dynamic_url}/reference-members`,
        data
      );
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteReferenceMembers = createAsyncThunk(
  "deleteReferenceMembers",
  async (deleteId: Number, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.delete(
        `${dynamic_url}/reference-members/${deleteId}`
      );
      res.data.id = deleteId;
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDescpritionFromUrl = createAsyncThunk(
  "getDescpritionFromUrl",
  async ({ url }: { url: string }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.post(
        `${dynamic_url}/get-quicklinks-description`,
        {
          url,
        }
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// export const updateQuickLinks = createAsyncThunk(
//   "updateFaqs",
//   async (data: { data: any; id: number }, { dispatch, rejectWithValue ,getState}) => {
//     try {
//        const state = getState();
//        const dynamic_url = renderDynamicUrlFromState(state);

//       const res = await axiosInstance.put(`${dynamic_url}/faqs/${data.id}`, data);
//       REQUIRED__TOASTER(res.data);

//       return res.data;
//     } catch (error: any) {
//       if (!error.response) {
//         throw error;
//       }
//       return rejectWithValue(error.response.data);
//     }
//   },
// );
// export const updateOrder = createAsyncThunk(
//   "updateOrder",
//   async ({ data }: { data: any }, { dispatch, rejectWithValue ,getState}) => {
//     try {
//      const state = getState();
//      const dynamic_url = renderDynamicUrlFromState(state);

//       const res = await axiosInstance.post(
//         `${dynamic_url}/faqs/update-order`,
//         data,
//       );
//       REQUIRED__TOASTER(res.data);
//       return res.data;
//     } catch (error: any) {
//       ERROR__TOASTER(error);
//       return rejectWithValue(error.response.data);
//     }
//   },
// );
