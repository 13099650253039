/* eslint-disable consistent-return */
const TACLandingPageManager = [
  { id: 1, title: "FAQ", path: "/faqs" },
  { id: 2, title: "Our Teams", path: "/teams" },
  { id: 3, title: "Testimonial", path: "/testimonial" },
  { id: 4, title: "Blog Management", path: "/blog" },
  { id: 5, title: "SEO Management", path: "/seo" },
  { id: 6, title: "Weekly Learning", path: "/weekly-learning" },
  { id: 7, title: "Archive", path: "/archive" },
  { id: 8, title: "Date and Time", path: "/product-pages-date-time" },
  { id: 9, title: "Apply Now", path: "/apply-now" },
  { id: 10, title: "Product Page Testimonial", path: "/product-page-testimonial" },
  { id: 11, title: "Our Classes", path: "/our-classes" },
];
const AALandingPageManager = [
  { id: 1, title: "FAQ", path: "/faqs" },
  { id: 2, title: "Our Teams", path: "/teams" },
  { id: 3, title: "Testimonial", path: "/testimonial" },
  { id: 4, title: "Blog Management", path: "/blog" },
  { id: 5, title: "SEO Management", path: "/seo" },
  { id: 6, title: "Weekly Learning", path: "/weekly-learning" },
  { id: 7, title: "Webinar", path: "/webinar" },
];

export const renderLandingPageManagerNavLinks = (
  membership_enabled: string
): any[] | null | undefined => {
  switch (membership_enabled) {
    case "0":
      return AALandingPageManager;

    case "1":
      return TACLandingPageManager;

    default:
      break;
  }
};

const TACPageManagerLinks = [
  { id: 1, title: "Terms of Use ", path: "/terms-of-use" },
  { id: 2, title: "Privacy", path: "/privacy-policy" },
  { id: 3, title: "About us", path: "/about-us" },
  { id: 4, title: "Site Manager", path: "/site-manager" },
];
const AAPageManagerLinks = [
  { id: 1, title: "Terms of Use ", path: "/terms-of-use" },
  { id: 2, title: "Privacy", path: "/privacy-policy" },
  { id: 3, title: "About us", path: "/about-us" },
  { id: 4, title: "Tuition Options", path: "/tuitions" },
];

export const renderPageManagerNavLinks = (
  membership_enabled: string
): any[] | null | undefined => {
  switch (membership_enabled) {
    case "0":
      return AAPageManagerLinks;

    case "1":
      return TACPageManagerLinks;

    default:
      break;
  }
};
