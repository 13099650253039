/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronDown, ChevronUp } from "react-feather";

import "./interviewinstruction.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  getInterviewQuestionsbyRoleId,
  getInterviewRoles,
} from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationApi";
import { setUserSigngleNotificatioData } from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationSlice";
import TourInfoPopup from "../Tour/TourInfo/TourInfoPopup";
import { goToNextStep, goToPrevStep } from "../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";

const InterviewInstructionComp = () => {
  interface Irole {
    id: number;
    name: string;
  }
  const [isActive, setIsActive] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Select a role ");
  const [selectedRoleId, setselectedRoleId] = useState<number>();

  const toggleActiveClass = isActive ? "active" : "";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });

  // For interview tour popup
  const {showInnerPopup,currentStep,steps,second_step} = useAppSelector((store:RootStore) => store.ShowPopupSlice);
  const singleStepData = steps.filter((item)=>item.id === currentStep);

  useEffect(() => {
    dispatch(getInterviewRoles());
    dispatch(setUserSigngleNotificatioData(null));
  }, []);

  const handleRoleSelection = (role: Irole) => {
    setSelectedRole(role.name);
    setselectedRoleId(role.id);
    setIsActive(false);

    if(selectedRole && !second_step){
        dispatch(goToNextStep());
    }
   
  };

  const navigateToInterviewPrep =()=>{
    navigate("/interview-preparation", {
      state: { roleId: selectedRoleId, role: selectedRole },
    });
    dispatch(goToNextStep());

  }

  const handleNext = () => {
    dispatch(goToNextStep());
  };

  const handlePrev = () => {
    dispatch(goToPrevStep());
  };

  return (
    <div className="interview__wrapper__div">
      <div className="interview">
        <div className="content-center">
          <h1>Interview preparation</h1>
          <div className="line" />
          <p className="warmup">Warm up / Instruction</p>

          <div className="contentpara">
            Preparing for any interview can be a nerve-wracking experience, but
            with the right preparation, you can increase your chances of success.
            Here are some tips to help you ace your video interview:
          </div>
          <ul className="C">
            <li>
              Review your recording: Watch your recording and assess your
              performance. You'll have a few minutes to respond to each question,
              and you can replay your recordings as many times as you like before
              submitting them.{" "}
            </li>
            <li>
              Feedback and tips: Admin will provide feedback and ratings on your
              performance based on the recordings. Make sure to come back for
              feedback and tips after submitting your recording.{" "}
            </li>
            <li>
              Ratings: Similarly, admin will add ratings based on your recordings.
              Re-record and try to achieve best ratings to nail your next
              interview.
            </li>
          </ul>
          <div className="answer__tagline">
            <p>If you are ready, select a role and record your answer.</p>
          </div>
          <div className="dropdown">
            <div
              className={`dropdown-btn ${toggleActiveClass}`}
              onClick={(e) => setIsActive(!isActive)}
            >
              <span> {selectedRole}</span>
              {isActive ? <ChevronUp /> : <ChevronDown />}
            </div>
            {isActive && (
              <div className="dropdown-content">
                {interviewState.req_success.role &&
                  interviewState.roles.map((role: Irole) => {
                    return (
                      <div
                        key={role.id}
                        className="dropdown-item"
                        onClick={(e) => {
                          handleRoleSelection(role);
                        }}
                      >
                        {role.name}
                      </div>
                    );
                  })}
              </div>
            )}
     
             
          </div>
          {
                showInnerPopup && ( <TourInfoPopup title={singleStepData[0].title} message={singleStepData[0].message} handleOnClickBack={handlePrev} handleOnClickNext={handleNext} id={singleStepData[0].id} selectedRoleId={selectedRoleId} selectedRole={selectedRole}/>)
            }
          <div className="interview__btn">
            <button
              onClick={navigateToInterviewPrep}
              disabled={!interviewState.req_success.role || !selectedRoleId}
            >
              Let’s start!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewInstructionComp;
