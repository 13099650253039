import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getInterviewRoles,
  getInterviewQuestionByRoleId,
  addOrUpdateQuestions,
  deleteQuestion,
  deleteMultipleQuestion
} from "./InterviewApi";

const initialState: any = {
  loading: {
    getRole: false,
    get: false,
    add: false,
    delete: false,
  },
  req_success: {
    getRole: false,
    get: false,
    add: false,
    delete: false,
  },
  edit: false,
  singleData: {},
  all_roles: [],
  all_questions: [],
  questions: [],
};

const AdminInterview = createSlice({
  name: "AdminInterview",
  initialState,
  reducers: {
    editInterview: (state: any, { payload }: PayloadAction<any>) => {
      state.edit = true;
      state.singleData = payload;
    },
    closeInterviewModal: (state: any) => {
      state.edit = false;
      state.singleData = {};
    },

    searchInterview: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.questions = state.all_questions.filter((plan: any) =>
          plan.question.toLowerCase().includes(payload.toLowerCase())
        );
      } else {
        state.questions = state.all_questions;
      }
    },
  },
  extraReducers: (builder) => {
    // GET ALL INTERVIEW ROLES
    builder.addCase(getInterviewRoles.pending, (state) => {
      state.loading.getRole = true;
      state.req_success.getRole = false;
    });
    builder.addCase(
      getInterviewRoles.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.getRole = false;
        state.req_success.getRole = true;
        state.all_roles = payload?.data;
      }
    );
    builder.addCase(getInterviewRoles.rejected, (state) => {
      state.loading.getRole = false;
    });

    // GET ALL QUESTIONS AND ANSWERS BY ROLE ID
    builder.addCase(getInterviewQuestionByRoleId.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getInterviewQuestionByRoleId.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.questions = payload.data;
        state.all_questions = payload.data;
      }
    );
    builder.addCase(getInterviewQuestionByRoleId.rejected, (state) => {
      state.loading.get = false;
    });

    // ADD OR UPDATE QUESTIONS
    builder.addCase(addOrUpdateQuestions.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      addOrUpdateQuestions.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
        state.edit = false;
        if (payload?.idForUpdate > 0) {
          state.all_questions = state.all_questions.map((question: any) => {
            if (question.id === payload?.data.id) {
              return payload?.data;
            }
            return question;
          });
        }

        if (payload?.idForUpdate === 0) {
          state.all_questions = [...state.all_questions, payload?.data];
        }
        state.questions = state.all_questions;
      }
    );
    builder.addCase(addOrUpdateQuestions.rejected, (state) => {
      state.loading.add = false;
    });

    //  DELETE question
    builder.addCase(deleteQuestion.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteQuestion.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        // state.questions = state.questions.filter(
        //   (question: any) => question.id !== payload?.id
        // );

        // state.all_questions = state.all_questions.filter(
        //   (question: any) => question.id !== payload?.id
        // );
      }
    );
    builder.addCase(deleteQuestion.rejected, (state) => {
      state.loading.delete = false;
    });

    //  DELETE multiple question
    builder.addCase(deleteMultipleQuestion.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteMultipleQuestion.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
      }
    );
    builder.addCase(deleteMultipleQuestion.rejected, (state) => {
      state.loading.delete = false;
    });
  },
});
export const { editInterview, closeInterviewModal, searchInterview } =
  AdminInterview.actions;
export default AdminInterview.reducer;
