/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Icon } from "@iconify/react";
import { X ,Trash2} from "react-feather";

import "./userheader.scss";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import SelectField from "../../../../shared/Inputs/SelectField/SelectField";
import {
  addUsers,
  downloadUserCsvFile,
  downloadUserExcelFile,
  getusersByFilter,
  loadUsers,
  rensedInvitation,
  updateUsers,
} from "../../../../store/features/AdminPanel/User/UserApi";
import {
  cleanUploaderScreen,
  closeEditModal,
  refreshUser,
  searchUsers,
} from "../../../../store/features/AdminPanel/User/UserSlice";
import UserFileUploaderModal from "../UserFileUploaderModal/UserFileUploaderModal";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import { roles } from "../../../../helpers/utils/Constants/roles";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";
import formOptions from "./helper";


const UserHeader = (props: any) => {
  const { downloadSampleCsv, downloadCsvLoading ,showSelectCheckbox,setShowSelectCheckbox,setDeleteAllUserModal,checkedUsers,isActiveDeleteAllUsers,setCheckedUsers,setSelectAllChecked} = props;
  const [showModal, setShowModal] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [roleError, setRoleError] = useState("");
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showUserFileDownload, setShowUserFileDownload] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [csv, setCsv] = useState(false);
  const [xls, setXls] = useState(false);
  const [allUser, setAllUser] = useState(false);
  const [icpStudent, setIcpStudent] = useState(false);
  const [apoStudent, setApoStudent] = useState(false);

  const [filterIcpUsers, setFilterIcpUsers] = useState(false);
  const [filterApoUsers, setFilterApoUsers] = useState(false);

  const [paid, setPaid] = useState(false);
  const [free, setFree] = useState(false);

  const [userSubscriptionRole, setuserSubscriptionRole] = useState(false);
  const [icpRole, setIcpRole] = useState(false);
  const [apoRole, setApoRole] = useState(false);
  const [allRole, setAllRole] = useState(false);

  const downloadUserFilebtnRef: any = useRef(null);
  const downloadUserFileDropdownRef: any = useRef(null);

  const fiterButtonRef: any = useRef(null);
  const filterDropdownRef: any = useRef(null);


  const options = [
    { value: "day", label: "Day" },
    { value: "month", label: "Month" },
    { value: "year", label: "Year" },
  ];

  const dispatch: any = useAppDispatch();
  const userState: any = useAppSelector((store: RootStore) => {
    return store.UserSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const isTac = Number(themeState?.configs?.membership_enabled) === 1;
  const isAA =Number(themeState?.configs?.membership_enabled) === 0;
  const showDuration = isTac ? userSubscriptionRole || allRole : isAA;

  useEffect(() => {
    dispatch(searchUsers(searchText));
  }, [searchText]);

  const downloadFile = () => {
    if (csv && allUser) {
      dispatch(downloadUserCsvFile(null));
    }
    if (xls && allUser) {
      dispatch(downloadUserExcelFile(null));
    }
    if (csv && icpStudent) {
      dispatch(downloadUserCsvFile(roles.ICP_STUDENT));
    }
    if (xls && icpStudent) {
      dispatch(downloadUserExcelFile(roles.ICP_STUDENT));
    }
    if (csv && apoStudent) {
      dispatch(downloadUserCsvFile(roles.APO_STUDENT));
    }
    if (xls && apoStudent) {
      dispatch(downloadUserExcelFile(roles.APO_STUDENT));
    }
  };

  interface FilterData {
    role?: string;
    plan?: string;
  }
  useEffect(() => {
    if (!filterIcpUsers && !filterApoUsers && !free && !paid) {
      dispatch(loadUsers());
    } else {
      const filterData: FilterData = {
        role: "",
        plan: "",
      };

      if (filterApoUsers || filterIcpUsers) {
        filterData.role = `${filterApoUsers ? `${roles.APO_STUDENT},` : ""}${
          filterIcpUsers ? `${roles.ICP_STUDENT}` : ""
        }`;
      }

      if (free || paid) {
        filterData.plan = `${free ? "free," : ""}${paid ? "paid" : ""}`;
      }

      dispatch(getusersByFilter(filterData));
    }
  }, [filterApoUsers, filterIcpUsers, free, paid]);

  useEffect(() => {
    if (userState.downloadSuccess) {
      setCsv(false);
      setXls(false);
      setShowUserFileDownload(false);
      setAllUser(false);
      setIcpStudent(false);
      setApoStudent(false);
    }
  }, [userState.downloadSuccess]);

  const handleResetAllFilter = () => {
    setFilterIcpUsers(false);
    setFilterApoUsers(false);
    setPaid(false);
    setFree(false);
    setShowFilterDropdown(false);
  };

  const clearAllSelectedRole = () => {
    setuserSubscriptionRole(false);
    setIcpRole(false);
    setApoRole(false);
    setAllRole(false);
  };
  const handleIndividualRoleCheck = () => {
    setAllRole(false);
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm<any>(formOptions(showDuration));
  const onSubmit = (values: any) => {
    let selectedRoles: string[] = [];
    values.duration_value = values.duration_value || ""
    const data = {
      ...values,
      role:Number(themeState?.configs?.membership_enabled) === 1 ? selectedRoles : values.role,
      duration_value:showDuration ? parseInt(values.duration_value) : Number(1),
      duration_period:showDuration? values.duration_period.value :"day",
    };
    if(Number(themeState?.configs?.membership_enabled) === 1){
      if (!userSubscriptionRole && !apoRole && !icpRole) {
        setRoleError("Please select at least one role");
        return;
      }
  
      setRoleError("");
  
      if (userSubscriptionRole) {
        selectedRoles.push("user");
      }
      if (icpRole) {
        selectedRoles.push("icp_student");
      }
      if (apoRole) {
        selectedRoles.push("apo_student");
      }
      if (allRole) {
        selectedRoles = ["user", "icp_student", "apo_student"];
      }
      if (!userSubscriptionRole && !apoRole && !icpRole) {
        setError("role", { message: "Please select at least one role" });
        return;
      }

    }
 
    if (userState.editUser) {
      dispatch(updateUsers({ userId: userState.single_user_info.id, data }));
    } else {
      dispatch(addUsers(data));
    }
    // dispatch(refreshUser());
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const handleModal = () => {
    reset();
    dispatch(closeEditModal(false));
    clearAllSelectedRole();
  };

  useEffect(() => {
    if (!userState.adderLoading && userState.edit_success) {
      setShowModal(false);
      handleModal()
    }
    if (!userState.adderLoading && userState.add_success) {
      setShowModal(false);
      reset();
      clearAllSelectedRole();
      // setValue("duration_period", {
      //   label: "Select Option",
      // });
    }
  }, [userState.edit_success,userState.add_success]);
  useEffect(() => {
    if (userState.editUser) {
      setShowModal(true);
      setValue("first_name", userState.single_user_info.first_name);
      setValue("last_name", userState.single_user_info.last_name);
      setValue("email", userState.single_user_info.email);
      setValue("duration_value", userState.single_user_info?.duration_number);
      if (userState.single_user_info.duration) {
        setValue("duration_period", {
          value: userState.single_user_info.duration,
          label:
            userState.single_user_info.duration.charAt(0).toUpperCase() +
            userState.single_user_info.duration.slice(1),
        });
      } else {
        // setValue("duration_period", {
        //   label: "Select Option",
        // });
      }
      if(Number(themeState?.configs?.membership_enabled) === 1){

        const { all_roles } = userState.single_user_info;
        if (all_roles.includes("user") && userState.single_user_info.plan !=="free") {
          setuserSubscriptionRole(true);
        }
        if (all_roles.includes("icp_student")) {
          setIcpRole(true);
        }
        if (all_roles.includes("apo_student")) {
          setApoRole(true);
        }
        if (
          all_roles.includes("user") && userState.single_user_info.plan !=="free" &&
          all_roles.includes("icp_student") &&
          all_roles.includes("apo_student")
        ) {
          setAllRole(true);
        }
      }

      }

  }, [userState?.editUser, userState?.single_user_info]);

  useEffect(() => {
    if (userState.uploaderSuccess) {
      setShowFileUploadModal(false);
      dispatch(cleanUploaderScreen());
      dispatch(loadUsers());
    }
  }, [userState.uploaderSuccess]);

  const handleClickOutSide = (event: any) => {
    if (
      downloadUserFileDropdownRef.current &&
      !downloadUserFileDropdownRef.current.contains(event.target)
    ) {
      if (
        downloadUserFilebtnRef.current &&
        !downloadUserFilebtnRef.current.contains(event.target)
      ) {
        setShowUserFileDownload(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
  }, []);

  const handleDeleteAllUser = ()=>{
    setDeleteAllUserModal(true);
  }
  const handleCancelcheckBox = ()=>{
    setShowSelectCheckbox(false)
    setCheckedUsers([]);
    setSelectAllChecked(false);
    // setAllUser(false);
  }



  const handleAllRoleCheck = (checked: boolean) => {
    setAllRole(checked);
    setuserSubscriptionRole(checked);
    setIcpRole(checked);
    setApoRole(checked);
  };

  const handleClickOutSidefilterDropDown = (event: any) => {
    if (
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target)
    ) {
      if (
        fiterButtonRef.current &&
        !fiterButtonRef.current.contains(event.target)
      ) {
        setShowFilterDropdown(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSidefilterDropDown);
  }, []);

  const showClearFilterButton =
    filterIcpUsers || filterApoUsers || free || paid;

  return (
    <div className="user__table__header_container">
      <div className="left__section">
        <TableHeadingTitle>All Users</TableHeadingTitle>
      </div>
      <div className="right__section user__table__right__section">
        {Number(themeState?.configs?.membership_enabled) === 1 && (
          <div className="dorpdown__handle">
            <div
              className="download__user__file__btn__width__handle"
              aria-hidden
              onClick={() => setShowFilterDropdown(!showFilterDropdown)}
              ref={fiterButtonRef}
            >
              <button className="filter__button" type="button">
                <Icon icon="bi:filter" className="filter__icon" /> Filters
              </button>
            </div>
            {showFilterDropdown && (
              <div
                className="download__user__file__download__dropdown__wrapper"
                ref={filterDropdownRef}
              >
                <div className="download__user__file__download__dropdow">
                  <div className="checkboxes__container mb-15">
                    <div className="filter__header">
                      <p>Filter by users</p>
                      <X
                        className="filter__close"
                        onClick={() => setShowFilterDropdown(false)}
                      />
                    </div>
                    <div className="checkboxes">
                      <div className="checkbox__wrapper__div">
                        {" "}
                        <CheckBox
                          label="ICP users"
                          tacCheckBoxvalue={filterIcpUsers}
                          onClick={() => setFilterIcpUsers(!filterIcpUsers)}
                          fullwidth
                        />
                      </div>
                      <div className="checkbox__wrapper__div">
                        {" "}
                        <CheckBox
                          label="APO users"
                          tacCheckBoxvalue={filterApoUsers}
                          onClick={() => setFilterApoUsers(!filterApoUsers)}
                          fullwidth
                        />
                      </div>
                    </div>
                  </div>

                  <div className="checkboxes__container mb-20">
                    <p>Filter by subscription</p>
                    <div className="checkboxes">
                      <div className="checkbox__wrapper__div">
                        {" "}
                        <CheckBox
                          label="Paid"
                          tacCheckBoxvalue={paid}
                          onClick={() => setPaid(!paid)}
                          fullwidth
                        />
                      </div>
                      <div className="checkbox__wrapper__div">
                        <CheckBox
                          label="Free"
                          tacCheckBoxvalue={free}
                          onClick={() => setFree(!free)}
                          fullwidth
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider__line" />
                  <span
                    className="filter__reset"
                    onClick={() => handleResetAllFilter()}
                  >
                    Reset all
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        {Number(themeState?.configs?.membership_enabled) === 1 &&
          showClearFilterButton && (
            <button type="button" className="clear__button">
              Clear filters{" "}
              <X
                className="clear__filter"
                onClick={() => handleResetAllFilter()}
              />
            </button>
          )}

        <div className="search__box">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>

        {Number(themeState?.configs?.membership_enabled) === 1 && (
          <div className="dorpdown__handle">
            <div
              className="download__user__file__btn__width__handle"
              aria-hidden
              ref={downloadUserFilebtnRef}
            >
              <button className={`download__user__file__btn ${showSelectCheckbox ? "disable":""}`} type="button"
                disabled={showSelectCheckbox}  onClick={() => setShowUserFileDownload(!showUserFileDownload)}>
                Download User File
              </button>
            </div>
            {showUserFileDownload && (
              <div
                className="download__user__file__download__dropdown__wrapper"
                ref={downloadUserFileDropdownRef}
              >
                <div className="download__user__file__download__dropdow">
                  <div className="checkboxes__container mb-15">
                    <p>Download (Users)</p>
                    <div className="checkboxes">
                      <div className="checkbox__wrapper__div">
                        <CheckBox
                          label="All users"
                          tacCheckBoxvalue={allUser}
                          onClick={() => setAllUser(!allUser)}
                          fullwidth
                        />
                      </div>
                      <div className="checkbox__wrapper__div">
                        {" "}
                        <CheckBox
                          label="ICP users"
                          tacCheckBoxvalue={icpStudent}
                          onClick={() => setIcpStudent(!icpStudent)}
                          fullwidth
                        />
                      </div>
                    </div>
                    <div className="checkboxes">
                      <div className="checkbox__wrapper__div">
                        {" "}
                        <CheckBox
                          label="APO users"
                          tacCheckBoxvalue={apoStudent}
                          onClick={() => setApoStudent(!apoStudent)}
                          fullwidth
                        />
                      </div>
                    </div>
                  </div>

                  <div className="checkboxes__container mb-20">
                    <p>Download in (Format)</p>
                    <div className="checkboxes">
                      <div className="checkbox__wrapper__div">
                        <CheckBox
                          label="CSV"
                          tacCheckBoxvalue={csv}
                          onClick={() => setCsv(!csv)}
                          fullwidth
                        />
                      </div>
                      <div className="checkbox__wrapper__div">
                        {" "}
                        <CheckBox
                          label="XLS"
                          tacCheckBoxvalue={xls}
                          onClick={() => setXls(!xls)}
                          fullwidth
                        />
                      </div>
                    </div>
                  </div>
                  <div className="download__btn__width">
                    <PrimaryButton
                      onClick={downloadFile}
                      text="Download"
                      loading={userState.downloadLoading}
                      disable={
                        !((csv || xls) && (allUser || icpStudent || apoStudent))
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {(authState.tac_user?.role === roles.SYSTEM_ADMIN || authState.tac_user?.role === roles.SUB_ADMIN) && (
          <div className="button__witdh__handle">
            <PrimaryButton
              text="Add New User"
              size="sm"
              type="button"
              disableBtnStyle={showSelectCheckbox}
              disable={showSelectCheckbox}
              loading={false}
              onClick={() => setShowModal(true)}
            />
          </div>
        )}

        {configState.configs?.membership_enabled === Domain.TAA &&
          (authState.tac_user?.role === roles.SYSTEM_ADMIN ||
             authState.tac_user?.role === roles.SUB_ADMIN) && (
            <div className="file__upload__btn">
              <PrimaryButton
                text="Upload Bulk User"
                size="sm"
                type="button"
                disableBtnStyle={showSelectCheckbox}
                disable={showSelectCheckbox}
                // loading={false}
                onClick={() => setShowFileUploadModal(true)}
              />
            </div>
          )}
          {
            showSelectCheckbox && (
              <>
               <div className="vertical__line" />
               <div className="deleteall__cancel__btn">
            
                <button type="button" disabled={!isActiveDeleteAllUsers} className={`deleteall__button  ${!isActiveDeleteAllUsers ? "activeDeleteAllbtn":""}`} onClick={()=>handleDeleteAllUser()}>
                <Trash2 size={16} className="delete__icon"  />
                  <p>Delete Users</p>
              </button>

              <button type="button" className="cancel__button" onClick={()=>handleCancelcheckBox()}>Cancel</button>
            </div>
              </>
            )
          }
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        resetForm={reset}
        onClick={handleModal}
      >
        <ModalHeader
          title={userState.editUser ? "Update User" : "Add New User"}
        />
        <ModalBody>
          <form
            className="user__form__container"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="flex">
              <div className="sm_width">
                <TextField
                  type="text"
                  label="First Name"
                  name="first_name"
                  register={register}
                  error={errors?.first_name}
                />
              </div>
              <div className="sm_width ">
                <TextField
                  type="text"
                  label="Last Name"
                  name="last_name"
                  register={register}
                  error={errors?.last_name}
                />
              </div>
            </div>

            <TextField
              type="text"
              label="Email"
              name="email"
              register={register}
              error={errors?.email}
              disabled={userState.editUser}
            />

        {
          Number(themeState?.configs?.membership_enabled) === 0 && (<input
            type="text"
            style={{ display: "none" }}
            value={["user"]}
            {...(register && register("role"))}
          />)
        }
            

            {Number(themeState?.configs?.membership_enabled) === 1 && (
              <>
                {/* Select Roles Design  */}
                <div className="roles__boxes">
                  <p className="select__role__text">Select Role</p>
                  <div className="role__checkboxes">
                    <div className="checkbox__wrapper__div">
                      {" "}
                      <CheckBox
                        label="User subscription"
                        tacCheckBoxvalue={userSubscriptionRole}
                        onClick={() => {
                          setuserSubscriptionRole(!userSubscriptionRole);
                          handleIndividualRoleCheck();
                        }}
                        fullwidth
                      />
                    </div>

                    <div className="checkbox__wrapper__div">
                      {" "}
                      <CheckBox
                        label="ICP"
                        tacCheckBoxvalue={icpRole}
                        onClick={() => {
                          setIcpRole(!icpRole);
                          handleIndividualRoleCheck();
                        }}
                        fullwidth
                      />
                    </div>

                    <div className="checkbox__wrapper__div">
                      {" "}
                      <CheckBox
                        label="APO"
                        tacCheckBoxvalue={apoRole}
                        onClick={() => {
                          setApoRole(!apoRole);
                          handleIndividualRoleCheck();
                        }}
                        fullwidth
                      />
                    </div>
                    <div className="checkbox__wrapper__div">
                      {" "}
                      <CheckBox
                        label="All"
                        tacCheckBoxvalue={allRole}
                        onClick={(e: any) => handleAllRoleCheck(!allRole)}
                        fullwidth
                      />
                    </div>
                  </div>
                  {roleError && <p className="error__message">{roleError}</p>}
                </div>
              </>
            )}

            {
              showDuration && (
                <div className="flex">
                <div className="sm_width">
                  <TextField
                    type="text"
                    label="Enroll to all the courses for:"
                    name="duration_value"
                    register={register}
                    error={errors?.duration_value}
                  />
                </div>
                <div className="sm_width ">
                  <Controller
                    control={control}
                    name="duration_period"
                    render={({ field }) => (
                      <SelectField
                        type="text"
                        label="Select Duration"
                        options={options}
                        register={register}
                        error={errors?.duration_period}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              )
            }

          

            <div className="modal_footer">
              {!userState.single_user_info.last_active &&
                !userState.resend_invitaiton_loading &&
                userState.editUser && (
                  <span
                    className="resend__invitation"
                    onClick={() =>
                      dispatch(
                        rensedInvitation({
                          email: userState.single_user_info.email,
                        })
                      )
                    }
                  >
                    Resend Invitation
                  </span>
                )}
              {userState.resend_invitaiton_loading && (
                <div className="spinner left-margin" />
              )}
              <div
                className={`add_button__witdh mt-1 ${
                  userState.editUser ? "update" : ""
                }`}
              >
                <PrimaryButton
                  text={userState.editUser ? "Update" : "Add"}
                  type="submit"
                  loading={userState?.adderLoading}
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <UserFileUploaderModal
        downloadSampleCsv={downloadSampleCsv}
        downloadCsvLoading={downloadCsvLoading}
        showFileUploadModal={showFileUploadModal}
        setShowFileUploadModal={setShowFileUploadModal}
      />
    </div>
  );
};

export default UserHeader;
