import React from "react";
import { useLocation } from "react-router-dom";

import "./questempty.scss";
import darkModeImg from "../../../../assets/images/file.svg";
import LightModeImg from "../../../../assets/images/loight.svg";
import { RootStore, useAppSelector } from "../../../../store/store";

const QuestEmptyComp = () => {
  const location: any = useLocation();
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <section className="emptyQuest">
      <div className="empty">
        <h1>Interview preparation</h1>
        <div className="role">
          <p>
            Selected role<span> {location.state.role}</span>
          </p>
        </div>
        <div className="line" />
        <div className="img">
          <img
            src={themeState.themeMode === "light" ? LightModeImg : darkModeImg}
            alt="emptypic"
          />
          <p>No questions added.</p>
        </div>
      </div>
    </section>
  );
};

export default QuestEmptyComp;
