/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prettier/prettier */
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { ChevronDown } from "react-feather";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../shared/Table/Table";
import {
  deleteUsers,
  loadUsers,
} from "../../../../store/features/AdminPanel/User/UserApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./userContent.scss";
import DeleteModal from "../../../../shared/DeleteModal/DeleteModal";
import { editUserInfo } from "../../../../store/features/AdminPanel/User/UserSlice";
import Avatar from "../../../../shared/Avatar/Avatar";
import userImage from "../../../../assets/icons/user.png";
import Empty from "../../../../shared/Empty/Empty";
import { roles } from "../../../../helpers/utils/Constants/roles";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";

const UserContent = (props: any) => {
  const {
    showSelectCheckbox,
    setShowSelectCheckbox,
    checkedUsers,
    handleCheckboxChange,
    selectAllChecked,
    handleSelectAllChange,
    setCheckedUsers
  } = props;
  const dispatch: any = useAppDispatch();
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [userId, setUserId] = useState<any>("");
  const [userEmail, setUserEmail] = useState<any>("");
  const [showDropDown, setShowDropdown] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(-1);

  const userState: any = useAppSelector((store: RootStore) => {
    return store.UserSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const createdDropdownRef = useRef<any>(null);
  const createdDropdownBoxRef = useRef<any>(null);


  useEffect(() => {
    dispatch(loadUsers());
  }, []);

  useEffect(() => {
    if (!userState.deleteLoading && userState.delete_success) {
      setDeleteShowModal(false);
    }
  }, [userState]);

  const openDeleteUser = (userId: any, userEmail: any) => {
    setDeleteShowModal(true);
    setUserId(userId);
    setUserEmail(userEmail);
     // Add the userId to the checkedUsers array if it's not already checked
    // if (!checkedUsers.includes(userId)) {
    //   setCheckedUsers((prev: any) => [...prev, userId]);
    // }
    setCheckedUsers([userId]);
  };
  const deleteUser = () => {
    dispatch(deleteUsers(userId));
  };

  const editUser = (user: any) => {
    dispatch(editUserInfo(user));
  };

  const handleCreatedDropDownBox = (uId: any) => {
    setShowDropdown(!showDropDown);
    setCurrentUserId(uId);
  };

  const handleClickOutSide = (event: any) => {
    if (
      createdDropdownRef.current &&
      !createdDropdownRef.current.contains(event.target)
    ) {
      if (
        createdDropdownBoxRef.current &&
        !createdDropdownBoxRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.addEventListener("mousedown", handleClickOutSide);
    };
  }, []);
  return (
    <div className="user__table__section__div">
      <Table
        heading={
          <TableHeading>
            <th className="title__wrapper">
              {showSelectCheckbox && (
                <CheckBox
                  labelClassname
                  value={selectAllChecked}
                  onClick={handleSelectAllChange}
                />
              )}
              <span>Name</span>
            </th>
            <th>Email</th>
            <th>Location</th>
            <th>State </th>
            <th>US Student</th>
            <th>Subscription</th>
            {configState.configs?.membership_enabled === Domain.TAC && (
              <th>Roles</th>
            )}
            <th>Created At</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {!userState.loading &&
            userState.users.map((user: any) => {
              return (
                <tr  key={user.id} style={{width:"100%"}}>
                  <td className="flex_td">
                    {showSelectCheckbox && (
                       <div className="checkbox__container__wrapper__div">
                         <CheckBox
                        labelClassname
                        value={checkedUsers.includes(user.id)}
                        onClick={(e:any) => handleCheckboxChange(e,user.id)}
                        />
                       </div>
                    )}
                    <div className={`avatar__section ${showSelectCheckbox && "avatar__marging__left"}`}>
                      <Avatar
                        imageUrl={user.avatar_url ?? userImage}
                        // size={sm}
                      />
                    </div>
                    <span>{user.name}</span>
                  </td>
                  <td className="email_td">{user.email}</td>
                  <td>{user.location || "-"}</td>
                  <td>{user.state || "-"}</td>
                  <td>-</td>
                  <td>{user.plan}</td>
                  {configState.configs?.membership_enabled === Domain.TAC && (
                    <td className="roles__tag__td">
                      {user.all_roles
                        .filter(
                          (role: any) =>
                            role === "icp_student" || role === "apo_student" ||  role === "pdm_student" || role === "icp_acc_student"
                        )
                        .map((role: any, index: number) => {
                          const formattedRole = role === "icp_acc_student" ? role
                          .split("_")[1]
                          .toUpperCase() : role
                            .split("_")[0]
                            .toUpperCase();
                          return <span>{formattedRole}</span>;
                        })}
                    </td>
                  )}

                  <td>
                    {/* {moment(user.created_at).format("ll")} */}
                    <div className="created__dropdown"    >
                      <div className="created__dropdown__header"  
                        onClick={() => handleCreatedDropDownBox(user.id)}>
                        <p>{moment(user.created_at).format("MM/DD/YYYY")}</p>
                      {user.enrolled_at && 
                         <ChevronDown />
                          } 
                      </div>
                      {
                        // user.enrolled_at &&
                        showDropDown && user.enrolled_at &&  currentUserId === user?.id && (    <div className="created__dropdownBox">
                        <div className="enrolled__on">Session Enrolled for: </div>
                        <div className="enrolle__dates">
                          <p>
                            {moment(user.enrolled_at).format("MM/DD/YYYY")} 
                            </p>
                        </div>
                    </div>)
                      }
                  
                    </div>
                  </td>

                  <TableAction
                    onEdit={() => editUser(user)}
                    onDelete={() => openDeleteUser(user.id, user.email)}
                    hideDelete={authState.tac_user?.role !== roles.SYSTEM_ADMIN}
                  />
                </tr>
              );
            })}
        </TableBody>
      </Table>
      {!userState.loading && userState.users.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
      )}
      <DeleteModal
        message={`The user with "${userEmail}" email address is about to be deleted. Please confirm this action as you won't be able to revert this action.`}
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={userState.deleteLoading}
        onClick={deleteUser}
        deleteAll={true}
        setShowSelectCheckbox={setShowSelectCheckbox}
      />
    </div>
  );
};

export default UserContent;
