import React, { useEffect, useLayoutEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Routes, useLocation, useNavigate } from "react-router-dom";

import PasswordChangeForm from "../../components/Auth/AuthForm/PasswordChangeForm/PasswordChangeForm";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import { RootStore, useAppDispatch, useAppSelector } from "../../store/store";
import { loadUser } from "../../store/features/Auth/AuthApi";
import { Domain } from "../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../helpers/utils/Constants/roles";
import { ROUTES } from "../../helpers/utils/Constants/route";
import "./index.scss";

function DefaultPasswordChange(props: any) {
  const navigate = useNavigate();
  const [authFail, setAuthFail] = useState(false);

  const dispatch = useAppDispatch();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  useLayoutEffect(() => {
    dispatch(loadUser({ setAuthFail: setAuthFail }));
  }, []);

  useEffect(() => {
    if (authFail) {
      navigate("/");
    }
  }, [authFail]);

  useEffect(() => {
    if (authState.authenticated && authState.tac_user) {
      const requiresPasswordChange =
        authState.tac_user.default_password_updated === 0;

      if (requiresPasswordChange) {
        navigate("/default-password-change");
      } else {
        handleRedirection(authState.tac_user.role);
      }
    }
  }, [authState.authenticated, authState.tac_user]);

  const handleRedirection = (role: string) => {
    switch (role) {
      case roles.PRODUCT_MANAGER_STUDENT:
        if (configState.configs.membership_enabled === Domain.TAC) {
          navigate(ROUTES.PRODUCT_MANAGER_COURSES);
        }
        break;
        case roles.USER:
          if (
            authState.tac_user.all_roles?.includes(roles.ICP_STUDENT) ||
            authState.tac_user.all_roles?.includes(roles.APO_STUDENT) && configState.configs.membership_enabled === Domain.TAC
          ) {
            navigate("/all-premium-courses"); 
          }
          else if (configState.configs.membership_enabled === Domain.TAA) {
            navigate("/academic-user-dashboard");
          }  
          else if (configState.configs.membership_enabled === Domain.TAC) { 
            navigate("/progress");
          }
          break;
      case "sys_adm":
      case roles.SUB_ADMIN:
        navigate("/dashboard");
        break;
      case "owner":
      case "admin":
        navigate("/organization");
        break;
      case "student":
        navigate("/organization-user-dashboard");
        break;
      case "mentor":
        navigate("/mentor/interview/recently-answered");
        break;
      default:
        break;
    }
  };

  const location = useLocation();
  const isLoginPage =
    location.pathname.includes("/") &&
    configState.configs.membership_enabled === Domain.TAA;
  const isTAC = configState.configs.membership_enabled === Domain.TAC;

  return (
    <>
      <ToastContainer />
      <div
        className={`${isLoginPage && "default__change__pasword"} ${
          isTAC && "Tac__default__change__pasword"
        }`}
      >
        <AuthLayout>
          <PasswordChangeForm />
        </AuthLayout>
      </div>
    </>
  );
}

export default DefaultPasswordChange;
