import { useEffect } from "react";
import ArchiveTable from "../../../components/NewAcademicUser/Archive/ArchiveTable";
import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { getDescriptionByUrl } from "../../../store/features/UserDashboard/Archives/ArchiveApi";

const AcademicUserArchive = () => {
  const dispatch = useAppDispatch();
  const archiveState: any = useAppSelector((store: RootStore) => {
    return store.ClassArchiveSlice;
  });

  useEffect(() => {
    dispatch(getDescriptionByUrl({ url: window.location.href.toString() }));
  }, []);

  return (
    <div>
      <Nav normal navBgColorHandle />

      <div className="new__academic__archive__padding__wrapper">
        <div className="info__section">
          <h1 className="title"> Class Archives</h1>
          <p className="info">{archiveState.desc}</p>
        </div>
        <div className="table__wapper">
          <ArchiveTable />
        </div>
      </div>
    </div>
  );
};

export default AcademicUserArchive;
