/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getClassArchives, getDescriptionByUrl } from "./ArchiveApi";

const initialState: any = {
  loading: false,
  req_success: false,
  desc_success: false,
  desc: "",
  all_archives: [],
  archives: [],
};

const ArchiveClassSlice = createSlice({
  name: "archive",
  initialState,
  reducers: {
    filterBydate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length === 0) {
        state.archives = state.all_archives;
      } else {
        state.archives = state.all_archives.filter((arhive: any) => {
          return arhive.filter_date === payload;
        });
      }
    },
  },
  extraReducers: (builder) => {
    // GET ALL ARCHIVE
    builder.addCase(getClassArchives.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getClassArchives.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_archives = payload?.data;
        state.archives = payload?.data;
      }
    );
    builder.addCase(getClassArchives.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getDescriptionByUrl.pending, (state) => {
      state.desc_success = false;
    });
    builder.addCase(
      getDescriptionByUrl.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.desc_success = true;
        state.desc = payload?.data;
      }
    );

    builder.addCase(getDescriptionByUrl.rejected, (state) => {});
  },
});
export const { filterBydate } = ArchiveClassSlice.actions;

export default ArchiveClassSlice.reducer;
