import { useEffect, useRef, useState } from "react";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import Table, { TableBody, TableHeading } from "../../../shared/Table/Table";
import SearchBar from "../../../shared/SearchBar/SearchBar";

import PaginateButton from "../../../shared/PaginateButton/PaginateButton";
import CheckBox from "../../../shared/Inputs/CheckBox/CheckBox";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import Empty from "../../../shared/Empty/Empty";
import "./index.scss";
import { renderDynamicUrlFromComponentLevelState } from "../../../helpers/utils/urlHelper";
import axiosInstance from "../../../apiConfigs/axiosInstance";
import {
  RootStore,
  useAppSelector,
} from "../../../store/store";

function UpcomingSessionsUsersComp(props: any) {
  const [searchText, setSearchText] = useState("");
  const [showUserFileDownload, setShowUserFileDownload] = useState(false);
  const [sessionUsers, setSessionUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  useEffect(() => {
    fetchSessionUsers();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchText]);

  const filterData = () => {
    const lowercasedSearch = searchText.toLowerCase();
    const filtered = sessionUsers.filter((item: any) =>
      item?.email.toLowerCase().includes(lowercasedSearch) || 
      item?.for.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredData(filtered);
  };

  const fetchSessionUsers = async () => {
    const dynamic_url = renderDynamicUrlFromComponentLevelState(configState);
    await axiosInstance.get(`${dynamic_url}/upcoming-session-users`).then((res) => {
      if (res.data.data) {
        setSessionUsers(res.data.data);
        setFilteredData(res.data.data);
      }
    });
  };

  const downloadUserFilebtnRef: any = useRef(null);
  const downloadUserFileDropdownRef: any = useRef(null);

  const handleClickOutSide = (event: any) => {
    if (
      downloadUserFileDropdownRef.current &&
      !downloadUserFileDropdownRef.current.contains(event.target)
    ) {
      if (
        downloadUserFilebtnRef.current &&
        !downloadUserFilebtnRef.current.contains(event.target)
      ) {
        setShowUserFileDownload(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
  }, []);

  return (
    <div>
      <BoxShadow>
        <div className="subscribers-bottom  mt-7">
          <div className="sh-table-top">
            <div className="left">Upcoming Sessions Users</div>
            <div className="right subscriber__table__head__right__section">
              <div className="searchbar__wrapper">
                {" "}
                <SearchBar value={searchText} setValue={setSearchText}/>
              </div>

              {/* <div
                    className="download__user__file__btn__width__handle"
                    aria-hidden
                    onClick={() =>
                      setShowUserFileDownload(!showUserFileDownload)
                    }
                    ref={downloadUserFilebtnRef}
                  >
                    <button className="download__user__file__btn" type="button">
                      Download User File
                    </button>
                  </div>
                  {showUserFileDownload && (
                    <div
                      className="download__user__file__download__dropdown__wrapper"
                      ref={downloadUserFileDropdownRef}
                    >
                      <div className="download__user__file__download__dropdow">
                        <div className="checkbox__wrapper">
                          {" "}
                          <CheckBox
                            label="CSV"
                            tacCheckBoxvalue={csv}
                            onClick={() => setCsv(!csv)}
                          />
                        </div>
                        <div className="checkbox__wrapper">
                          <CheckBox
                            label="XLS"
                            tacCheckBoxvalue={xls}
                            onClick={() => setXls(!xls)}
                          />
                        </div>
                        <div className="download__btn__width">
                          <PrimaryButton
                            onClick={downloadFile}
                            text="Download"
                            loading={subscriberState.downloadLoading}
                          />
                        </div>
                      </div>
                    </div>
              )} */}
            </div>
          </div>
          <div className="subscriber__talbe__section">
            <Table
              heading={
                <TableHeading>
                  <th>S.N.</th>
                  <th>Email Address</th>
                  <th className="">Created At</th>
                  <th className="">Course</th>
                  <th className="hide__text">.</th>
                </TableHeading>
              }
            >
              <TableBody>
                {filteredData.length > 0 && filteredData.map((item: any, index) => {
                  return (
                    <tr key={item.id}>
                      <td className="subscriber_name">{index+1}</td>
                      <td className="subscriber_name">{item.email}</td>
                      <td className="">{item.date}</td>
                      <td className="">{item.for.toUpperCase()}</td>
                      <td className="hide__text">.</td>
                    </tr>
                  );
                })}
              </TableBody>
            </Table>

            {filteredData.length === 0 && <div className="mt-5 handle__empty__container__div">
                  <Empty emptyText="It seems empty." custombg={true} />
                </div>}
          </div>
        </div>
      </BoxShadow>
      {/* <div className="pagination__section">
        <PaginateButton
          rowPerView={10}
          paginate={handlePagination}
          totalRows={sessionUsers.length}
        />
      </div> */}
    </div>
  );
}

export default UpcomingSessionsUsersComp;
