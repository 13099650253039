import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import "./userReference.scss";
import UserReferenceCard from "../../../components/NewAcademicUser/UserReference/UserReferenceCard/UserReferenceCard";
import {
  useAppSelector,
  RootStore,
  useAppDispatch,
} from "../../../store/store";
import {
  getDescpritionFromUrl,
  loadReferenceMembers,
} from "../../../store/features/UserDashboard/Reference/ReferenceApi";
import darkEmptyuserRefImg  from "../../../assets/images/user-reference-empty-dark-img.svg";
import lightEmptyUserRefImg from "../../../assets/images/no-reference-light.svg";

function UserReference(props: any) {
  const dispatch = useAppDispatch();

  
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const referenceState: any = useAppSelector((store: RootStore) => {
    return store.ReferenceSlice;
  });
  useEffect(() => {
    dispatch(getDescpritionFromUrl({ url: window.location.href.toString() }));
    dispatch(loadReferenceMembers("dashboard"));
  }, []);
  return (
    <>
      <Nav normal navBgColorHandle/>
      <div className="user-reference-body">
        <h1>We are here to help</h1>
        <p>{referenceState.description}</p>
        <div className="user-reference-card-container">
          {referenceState?.all_reference_members?.map((e: any) => {
            return (
              <UserReferenceCard
                name={e.name}
                email={e.email}
                img={e.avatar_url}
                companyName={e.company_name}
                phone={e.phone}
                designation={e.designation}
              />
            );
          })}
        </div>
        {
          referenceState?.all_reference_members?.length === 0 && (
        <div className="user__reference__empty__wrapper">
          <div className="user__reference__empty__container">
            <img src={themeState.themeMode === "dark" ? darkEmptyuserRefImg : lightEmptyUserRefImg} alt="" />
            <p>No references added. </p>
          </div>
        </div>)
        }
       
      </div>
    </>
  );
}

export default UserReference;
