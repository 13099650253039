/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Table, { TableBody, TableHeading } from "../../../../shared/Table/Table";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import "./interview.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { getInterviewRoles } from "../../../../store/features/AdminPanel/Interview/Question/InterviewApi";
import Empty from "../../../../shared/Empty/Empty";

const InterviewComp = (props: any) => {
  const { setOpenAddQuestionPage, setRole, backToHomeTab } = props;
  const dispatch = useAppDispatch();

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewSlice;
  });

  useEffect(() => {
    backToHomeTab();
    dispatch(getInterviewRoles());
  }, []);

  const openAddQuestionView = (role: any) => {
    setRole(role);
    setOpenAddQuestionPage(true);
  };

  return (
    <div className="admin__interview__wrapper_header">
      <div className="table_heading">
        <TableHeadingTitle>Interview Preparation</TableHeadingTitle>
      </div>
      <Table
        heading={
          <TableHeading>
            <th className="title">Role name</th>
            <th className="title heading__center"> Number of questions</th>
            <th className="title"> Last updated on</th>
          </TableHeading>
        }
      >
        <TableBody>
          {InterviewState?.all_roles?.length > 0 &&
            InterviewState.all_roles.map((role: any) => {
              return (
                <tr className="row" key={role.id}>
                  <td
                    role="button"
                    aria-hidden
                    onClick={() => openAddQuestionView(role)}
                  >
                    {/* {" "}
                    <Link
                      to="/interview/questions"
                      state={{ name: role.name, role_id: role.id }}
                      className="data hover"
                    >
                      {" "}
                    </Link> */}
                    {role.name}
                  </td>
                  <td className="data text_center"> {role.total_questions}</td>
                  <td className="data">{role.last_updated}</td>
                </tr>
              );
            })}
        </TableBody>
      </Table>

      {
          InterviewState?.all_roles?.length === 0 &&
          (<div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>)
        }
    </div>
  );
};

export default InterviewComp;
