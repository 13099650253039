export const filterArray = [
  {
    id: 1,
    name: "Business Analyst",
    value: "business_analyst",
  },

  {
    id: 2,
    name: "Product Owner",
    value: "product_owner",
  },

  {
    id: 3,
    name: "Scrum Master",
    value: "scrum_master",
  },
];

export const filterArrayForTAC = [
  {
    id: 1,
    name: "Product Manager",
    value: "product_manager",
  },
  {
    id: 2,
    name: "ICP",
    value: "icp",
  },
  {
    id: 3,
    name: "Agile Team Facilitator",
    value: "agile_team_facilitator",
  },
  {
    id: 4,
    name: "ICP-ACC",
    value: "icp_acc",
  },
  {
    id: 5,
    name: "ICP-APO",
    value: "apo",
  },
];
