import React from "react";
import "./userReferenceCard.scss";
import {
  RootStore,
  useAppSelector,
} from "../../../../store/store";

function UserReferenceCard(props: any) {
  const { name, email, phone, img, companyName, designation } = props;

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  return (
    <div className="user-reference-card">
      <div className="urc">
        <div className="urc-img">
          <img src={img} alt={name} />
        </div>
        <div className="urc-txt">
          <div className="urc-name" title={name}>
            {name}
          </div>
          <div className="urc-designation">{designation}</div>
          <div className="urc-companyname">{companyName}</div>
        </div>
      </div>
      <div className="urc-dashed" />
      <div className="urc-email">{email}</div>

      <div className="urc-num">{phone}</div>
    </div>
  );
}

export default UserReferenceCard;
