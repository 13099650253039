/* eslint-disable jsx-a11y/control-has-associated-label */
import "./referencebody.scss";

import React, { useEffect, useState } from "react";
import { Edit2, Trash } from "react-feather";

import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import Table, { TableHeading } from "../../../../shared/Table/Table";
import {
  deleteReferenceMembers,
  loadReferenceMembers,
} from "../../../../store/features/UserDashboard/Reference/ReferenceApi";
import { updateReferenceCurrID } from "../../../../store/features/UserDashboard/Reference/ReferenceSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import Empty from "../../../../shared/Empty/Empty";

function ReferenceBody(props: any) {
  const {
    isEdit,
    setIsEdit,
    openModal,
    setOpenModal,
    showDeleteModal,
    setDeleteShowModal,
  } = props;
  const dispatch = useAppDispatch();
  const referenceState: any = useAppSelector((store: RootStore) => {
    return store.ReferenceSlice;
  });

  const handleDelete = () => {
    dispatch(deleteReferenceMembers(referenceState?.currID));
  };

  useEffect(() => {
    dispatch(loadReferenceMembers("dashboard"));
  }, []);
  return (
    <div className="reference__body">
      <Table
        heading={
          <TableHeading>
            <th className="th__width_45">Name</th>
            <th className="th__width_20">Phone Number</th>
            <th className="th__width_45">Company Name</th>
            <th className="th__width_45">Designation</th>
            <th className="hide__text">. </th>
          </TableHeading>
        }
      >
        <tbody>
          {referenceState.all_reference_members.map((e: any) => {
            return (
              <tr className="table_row_text">
                <td className="user_td_section">
                  <div className="user_section_team">
                    <img className="user_img" src={e?.avatar_url} alt="team" />

                    <div className="user_name_sec">
                      <span>{e?.name}</span>
                      <span className="linked_in_link">{e?.email}</span>
                    </div>
                  </div>
                </td>
                <td className="team_designation__section">{e?.phone || "-"}</td>
                <td className="team_member__section">{e?.company_name}</td>
                <td className="team_designation__section">{e?.designation}</td>

                <td className="drag__team__action__icons ">
                  <div className="action__section__action">
                    <div className="drag_test_4544">
                      <Edit2
                        className="edit__icon"
                        onClick={() => {
                          dispatch(updateReferenceCurrID(e?.id));
                          setIsEdit(true);
                          setOpenModal(true);
                        }}
                      />
                      <Trash
                        className="delete__icon"
                        onClick={() => {
                          dispatch(updateReferenceCurrID(e?.id));
                          setDeleteShowModal(true);
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {referenceState.all_reference_members.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
         )}
         
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={referenceState.loading}
        onClick={handleDelete}
      />
    </div>
  );
}

export default ReferenceBody;
