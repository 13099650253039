
export const TestimonialFilterForTACOnly = [
    {
      id: 1,
      name: "ICP",
      value: "icp",
    },
    {
      id: 2,
      name: "APO",
      value: "apo",
    },
    {
      id: 3,
      name: "PDM",
      value: "pdm",
    },
    {
      id: 4,
      name: "ACC",
      value: "acc",
    },
    {
      id: 5,
      name: "ATF",
      value: "atf",
    },
  ];