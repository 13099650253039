import React, { useState } from "react"
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout"
import BoxShadow from "../../../shared/BoxShadow/BoxShadow"
import ProductPageTestimonialHeader from "../../../components/ProductPageTestimonial/ProductPageTestimonialHeader/ProductPageTestimonialHeader"
import ProductPageTestimonialContent from "../../../components/ProductPageTestimonial/ProductOageTestimonialContent/ProductPageTestimonialContent"
import { TestimonialFilterForTACOnly } from "../../../components/ProductPageTestimonial/ProductPageTestimonialHeader/helper";

const ProductPageTestimonial = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(TestimonialFilterForTACOnly[0]);
  const [editModal, setEditModal] = useState(false);
  const [currId, setCurrId] = useState(0);

  return (
    <AdminPanelLayout>
    <BoxShadow>
      <div className="mt-7">
        <ProductPageTestimonialHeader
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        showModal={showModal}
        setShowModal={setShowModal}
        editModal={editModal}
        setEditModal={setEditModal}
        currId={currId}
        setCurrId={setCurrId}
        />
        <ProductPageTestimonialContent
        selectedPage={selectedValue}
        setShowModal={setShowModal}
        setEditModal={setEditModal}
        currId={currId}
        setCurrId={setCurrId}
        />
      </div>
    </BoxShadow>
  </AdminPanelLayout>
  )
}

export default ProductPageTestimonial
