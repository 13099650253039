/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Edit2, Trash } from "react-feather";
import "./index.scss";
import Table, { TableHeading } from "../../../../shared/Table/Table";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  getClassArchives,
  deleteClassArchive,
  updateOrder,
} from "../../../../store/features/AdminPanel/Archives/ArchiveApi";
import {
  changeTempOrder,
  editArchiveInfo,
  setShowModal,
} from "../../../../store/features/AdminPanel/Archives/ArchiveSlice";
import Empty from "../../../../shared/Empty/Empty";

const ArchiveTable = () => {
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [archiveId, setArchiveId] = useState(0);
  const dispatch = useAppDispatch();

  const archiveState: any = useAppSelector((store: RootStore) => {
    return store.ArchiveSlice;
  });

  useEffect(() => {
    dispatch(getClassArchives());
  }, [archiveState.isChanged]);

  useEffect(() => {
    dispatch(getClassArchives());
  }, []);

  useEffect(()=>{
    if(archiveState.uploaderSuccess){
      dispatch(getClassArchives());
    }
  },[archiveState.uploaderSuccess])

  const editModalHandler = (arch: any) => {
    dispatch(editArchiveInfo(arch));
    dispatch(setShowModal());
  };
  const deleteModalHandler = (id: number) => {
    setArchiveId(id);
    setDeleteShowModal(true);
  };

  const deleteArchive = () => {
    dispatch(deleteClassArchive({ id: archiveId }));
    setDeleteShowModal(false);
  };

  const listenDrag = (result: any) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const data2 = {
      changeOrder: true,
      sourceIndex: source.index,
      destinationIndex: destination.index,
      draggableId: draggableId,
    };
    dispatch(changeTempOrder(data2));
    const data = { list: { [draggableId]: destination.index + 1 } };
    dispatch(updateOrder({ data: data }));
  };

  useEffect(() => {
    if (archiveState.order_req_success || archiveState.isChanged) {
      // dispatch(getClassArchives());
    }
  }, [archiveState.order_loading, archiveState.isChanged]);

  useEffect(() => {
    if (archiveState.req_success.get_all) {
      // console.log("Loaded");
    }
  }, [archiveState.loading.get_all]);
  // console.log(archiveState?.all_archives);

  const handleTimeChange = (e: any) => {
    if (e) {
      const timeSplit = e.split(":");
      const AmOrPm = timeSplit[0] >= 12 ? "pm" : "am";
      const hours = timeSplit[0] % 12 || 12;
      const minutes = timeSplit[1];
      return `${hours}:${minutes}${AmOrPm}`;
    }
    return "-";
  };

  return (
    <div className="archive__drag__table__container">
      <DragDropContext onDragEnd={listenDrag}>
        <Droppable droppableId="239999">
          {(provided) => (
            <Table
              heading={
                <TableHeading>
                  <th className="th__width_20">Date</th>
                  <th className="th__width_20">Time</th>
                  <th className="th__width_45">Topic</th>
                  <th className="th__width_20">Video Link</th>
                  <th className="hide__text">. </th>
                </TableHeading>
              }
            >
              <tbody ref={provided.innerRef} className="team_tbody">
                {!archiveState.loading.get_all &&
                  archiveState.req_success.get_all &&
                  archiveState?.all_archives.map((arch: any, index: number) => {
                    return (
                      // <Draggable
                      //   draggableId={`${arch?.id}`}
                      //   index={index}
                      //   key={`${arch?.id}`}
                      // >
                      //   {(provided) => {
                      //     return (
                      <tr
                        className="archive__table_row"
                        // {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <td className="date__td">{arch?.date}</td>
                        <td className="time__td">
                          {handleTimeChange(arch?.time_from)} -{" "}
                          {handleTimeChange(arch?.time_to)}
                        </td>
                        <td className="topic_td">{arch?.topic}</td>
                        <td className="link_td">
                          <a
                            href={arch?.video_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Zoom Link
                          </a>
                        </td>

                        <td className="drag__team__action__icons ">
                          <div className="action__section__action">
                            <div className="drag_test_4544">
                              <Edit2
                                className="edit__icon"
                                onClick={() => editModalHandler(arch)}
                              />
                              <Trash
                                className="delete__icon"
                                onClick={() => deleteModalHandler(arch?.id)}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      //     );
                      //   }}
                      // </Draggable>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
      {!archiveState.loading.get_all &&
        archiveState.req_success.get_all &&
        archiveState?.all_archives.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty
              emptyText="It seems empty."
              emptyColorFulText="Add class archive"
              emptyText2="now"
              custombg={true}
            />
          </div>
        )}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={archiveState.loading.delete}
        onClick={deleteArchive}
      />
    </div>
  );
};

export default ArchiveTable;
