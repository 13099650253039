export const roles = {
  SYSTEM_ADMIN: "sys_adm",
  SUB_ADMIN: "sub_admin",
  USER: "user",
  PRODUCT_MANAGER_STUDENT: "product_manager_student",
  TEAMMEMBERSHIP_OWNER: "owner",
  TEAMMEMBERSHIP_ADMIN: "admin",
  MENTOR: "mentor",
  OWNER: "owner",
  TENANT_ADMIN: "admin",
  TENANT_STUDENT: "student",
  BUISNESS_ANALYST_STUDENT: "business_analyst_student",
  SCRUM_MASTER_STUDENT: "scrum_master_student",
  PRODUCT_OWNER_STUDENT: "product_owner_student",
  ICP_STUDENT: "icp_student",
  APO_STUDENT:"apo_student"
};