/* eslint-disable no-useless-escape */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import {
  useAppSelector,
  RootStore,
  useAppDispatch,
} from "../../../../store/store";
import TextArea from "../../../../shared/Inputs/TextArea/TextArea";
import "./referencehead.scss";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  getBase64,
  getFileExtension,
} from "../../../../helpers/utils/FileHelper";
import { addReferenceMembers } from "../../../../store/features/UserDashboard/Reference/ReferenceApi";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";

function ReferenceHead(props: any) {
  const {
    isEdit,
    setIsEdit,
    openModal,
    setOpenModal,
    showDeleteModal,
    setDeleteShowModal,
  } = props;

  const [imageExt, setImageExt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageError, setImageError] = useState("");
  const hiddenFileInput = useRef<any>(null);

  const dispatch = useAppDispatch();
  const referenceState: any = useAppSelector((store: RootStore) => {
    return store.ReferenceSlice;
  });

  const handleClick = () => {
    hiddenFileInput && hiddenFileInput?.current?.click();
  };
  const removeImage = () => {
    setImageUrl("");
    setImageFile("");
    setImageError("Image is required !");
  };

  const getFiles = async (e: any) => {
    const contentFile = e.target.files[0];
    if (contentFile.size > 2 * 1024 * 1024) {
      setImageError("Image size must be less than 2 MB");
      return;
    }
    const result = URL.createObjectURL(contentFile);
    setImageUrl(result);
    const extension = getFileExtension(contentFile.name);
    setImageExt(extension);
    const base64Image: any = await getBase64(contentFile);
    setImageFile(base64Image);
    setImageError("");
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Name")),
    email: yup.string().required(REQUIRED_VALIDATION("Email")),
    // phone: yup.string().required(REQUIRED_VALIDATION("Phone number")),
    company_name: yup.string().required(REQUIRED_VALIDATION("Company name")),
    designation: yup.string().required(REQUIRED_VALIDATION("Designation")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    setValue,
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    let data;
    if (!imageFile) {
      const temp = referenceState.all_reference_members.filter((e: any) => {
        return e.id === referenceState.currID;
      });
      if (!temp[0].avatar_url) {
        setImageError("Image is required");
        return;
      }
      data = {
        ...values,
      };
    }
    if (imageFile) {
      data = {
        ...values,
        avatar_url: imageFile,
        extension: imageExt,
      };
    }
    if (isEdit) {
      dispatch(addReferenceMembers({ ...data, id: referenceState.currID }));
    } else {
      dispatch(addReferenceMembers(data));
    }
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    if (!referenceState.loading && referenceState.req_success) {
      reset();
      setImageUrl("");
      setOpenModal(false);
      setDeleteShowModal(false);
    }
  }, [referenceState.loading]);

  useEffect(() => {
    if (isEdit) {
      const temp = referenceState.all_reference_members.filter((e: any) => {
        return e.id === referenceState.currID;
      });
      // console.log("test", temp[0]);
      setValue("name", temp[0].name);
      setValue("email", temp[0].email);
      setValue("phone", temp[0].phone);
      setValue("designation", temp[0].designation);
      setValue("company_name", temp[0].company_name);
      setImageUrl(temp[0].avatar_url);
    }
  }, [isEdit]);

  useEffect(() => {
    if (!openModal) {
      setIsEdit(false);
      setImageUrl("");
      setImageFile("");
      setOpenModal(false);
      setDeleteShowModal(false);
      reset();
      setImageError("");
    }
  }, [openModal]);

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Reference</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            onClick={() => {
              setOpenModal(true);
            }}
            loading={false}
          />
        </div>
      </div>
      <Modal show={openModal} setShow={setOpenModal}>
        <ModalHeader title={isEdit ? "Edit Reference" : "Add Reference"} />
        <ModalBody>
          <form
            className="referenceform"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="form_row form2">
              <TextField
                type="text"
                label="Name"
                name="name"
                register={register}
                error={errors?.name}
              />
              <TextField
                type="email"
                label="Email"
                name="email"
                register={register}
                error={errors?.email}
              />
            </div>

            <div className="form_row form2">
              <TextField
                type="number"
                label="Phone Number"
                name="phone"
                register={register}
                error={errors?.phone}
              />
              <div className="reference_image_section">
                <div className="upload_avatar_sec">
                  {imageUrl ? (
                    <img className="author_img" src={imageUrl} alt="avatar" />
                  ) : (
                    <img
                      className="author_img"
                      src="https://placehold.jp/150x150.png"
                      alt="avatar"
                    />
                  )}

                  <div className="image__functionality">
                    <span className="upload_image" onClick={handleClick}>
                      Upload new avatar
                    </span>
                    <span className="remove_image" onClick={removeImage}>
                      Remove
                    </span>
                  </div>
                </div>
                <ErrorText error={imageError} />

                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  //   {...register("avatar")}
                  ref={hiddenFileInput}
                  onChange={(e: any) => getFiles(e)}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="form_row">
              <TextField
                type="text"
                label="Company Name"
                name="company_name"
                register={register}
                error={errors?.company_name}
              />
            </div>
            <div className="form_row">
              <TextField
                type="text"
                label="Designation"
                name="designation"
                register={register}
                error={errors?.designation}
              />
            </div>

            <div className="button__witdh mt-1">
              <PrimaryButton
                text={isEdit ? "Update" : "Add"}
                type="submit"
                loading={referenceState.loading}
                onClick={() => {
                  if (!isEdit && !imageFile) {
                    setImageError("Image is required.");
                  }
                }}
                disabled={
                  (isEdit && imageError.length !== 0) || referenceState.loading
                }
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ReferenceHead;
